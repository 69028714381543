import { css, keyframes } from "@emotion/core";
import { useTheme } from "emotion-theming";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";
import { useSpring } from "react-spring";
import { fullbleedgrid } from "../../../../styles/grid";
import {
  colors,
  hexRgba,
  mediaquery,
  rem,
  scaleSpacing,
  zIndexes,
} from "../../../../styles/theme";
import { UIContext } from "../../../contexts/ui";
import Button from "../../Atoms/Button";
import Image from "../../Atoms/Image";
import RichText from "../../Atoms/RichText";
import IconSvg from "../../Atoms/Svg";
import AdBanner from "../../Atoms/AdBanner";
import {
  NotoBody,
  OswaldH1,
  OswaldH2,
  OswaldOverline1,
  OswaldOverline2,
} from "../../Atoms/Typography";

const arrowBounce = keyframes`
	0% {
		transform: translateY(0px) rotate3d(0,0,1, 90deg);
	}

	20% {
		transform: translateY(-10px) rotate3d(0,0,1, 90deg);
	}

	40% {
		transform: translateY(0px) rotate3d(0,0,1, 90deg);
	}

	60% {
		transform: translateY(-10px) rotate3d(0,0,1, 90deg);
	}

	80% {
		transform: translateY(0px) rotate3d(0,0,1, 90deg);
	}

	100% {
		transform: translateY(0px) rotate3d(0,0,1, 90deg);
	}
`;

const arrowStyle = css`
  transform: rotate3d(0, 0, 1, 90deg);
  animation: ${arrowBounce} 5s ease-in-out infinite;
`;

const Container = styled.section`
  overflow: hidden;

  ${({ showAd }) =>
    showAd &&
    css`
      ${fullbleedgrid};
      background: ${colors.darkgrey2};

      ${mediaquery.md(css`
        padding-top: ${scaleSpacing(26)};
        padding-bottom: ${scaleSpacing(26)};
      `)}
    `}
  ${({ isSticky }) =>
    isSticky &&
    css`
      grid-column: 1;
      grid-row: 1;
      position: sticky;
      top: 0;
    `}
`;

const TopContainer = styled.div`
  position: relative;

  ${({ heightVh }) => css`
    padding: ${scaleSpacing(20)} 0;
    height: ${heightVh ? `${heightVh}vh` : "auto"};
    ${mediaquery.md(css`
      padding: ${scaleSpacing(25)};
    `)};
  `};

  ${({ showAd }) =>
    showAd &&
    css`
      grid-column: 1 / -1;

      ${mediaquery.md(css`
        grid-column: 2 / span 15;
        padding: 0;
        min-height: 500px;
      `)}
    `}
`;

const AdContainer = styled.div`
  grid-column: 1 / -1;

  ${mediaquery.md(css`
    grid-column: span 8 / -2;
    display: flex;
  `)};
`;

const ContentContainer = styled.div`
  z-index: ${zIndexes.top};
  position: relative;
  padding: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 2 * var(--external-gutter));
  height: 100%;
  color: ${({ color }) => color};

  ${({ showAd }) =>
    showAd &&
    css`
      grid-column: 1 / -1;

      ${mediaquery.md(css`
        grid-column: 2 / span 15;
        padding: 0;
      `)}
    `}

  ${mediaquery.md(css`
    width: calc(var(--col) * 12);
    margin-bottom: 0;
    padding-top: 0;
  `)};
`;

const Headline = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: ${scaleSpacing(4)};
  ${OswaldOverline2}

  ${mediaquery.md(css`
    ${OswaldOverline1}
  `)};
`;

const Title = styled(RichText)`
  color: inherit;
  text-align: center;
  ${OswaldH2}
  width: 100%;

  ${mediaquery.md(css`
    ${OswaldH1}
  `)};
`;

const Description = styled(RichText)`
  color: inherit;
  text-align: center;
  ${NotoBody}
  width: 100%;
  padding: 0 var(--gutter-size);
  margin-top: ${scaleSpacing(4)};

  ${mediaquery.md(css`
    padding: 0;
  `)};
`;

const ButtonScroll = styled.div`
  cursor: pointer;
  z-index: ${zIndexes.top};
  position: absolute;
  bottom: ${scaleSpacing(6)};
  width: ${rem(100)};
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;

  ${mediaquery.md(css`
    bottom: ${scaleSpacing(10)};
  `)};
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndexes.bottom};

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${hexRgba(colors.black, 0.4)};
    z-index: ${zIndexes.middle};
  }
`;

const Teaser = ({
  headline,
  title,
  description,
  ctaLabel,
  ctaLink,
  image,
  ads,
  hasNewsletterLink,
  hasScrollDown,
  showAd,
  heightVh,
  isSticky,
}) => {
  const { openModal } = useContext(UIContext);
  const theme = useTheme();
  const [offset, settOffset] = useState(false);

  const containerRef = useRef(null);

  const [, setScrollTop] = useSpring(() => ({
    immediate: false,
    reset: true,
    y: 0,
    onChange: ({ value }) => {
      window?.scrollTo(0, value.y);
    },
  }));

  const scrollDown = () => {
    containerRef?.current &&
      setScrollTop({
        from: { y: window.scrollY },
        to: {
          y: containerRef?.current?.offsetHeight + offset,
        },
      });
  };

  const showEngage = useCallback(() => {
    openModal({
      modalId: "newsletter",
      modalParams: { template: "allNewsletterModal" },
    });
  }, [openModal]);

  useEffect(() => {
    settOffset(containerRef?.current?.getBoundingClientRect().top);
  }, [containerRef]);

  return (
    <Container ref={containerRef} showAd={showAd} isSticky={isSticky}>
      <TopContainer heightVh={heightVh} showAd={showAd}>
        {image?.url && (
          <ImageContainer>
            <Image
              source={image}
              sizes="100vw"
              mobileSizes="100vw"
              objectFit="cover"
            />
          </ImageContainer>
        )}

        <ContentContainer color={theme?.foregroundColor}>
          {!!headline && <Headline>{headline}</Headline>}
          {!!title && <Title data={title} />}
          {!!description && <Description data={description} />}
          {!!ctaLabel && (
            <Button
              buttonTheme="primary"
              onClick={hasNewsletterLink ? showEngage : () => {}}
              link={ctaLink && !hasNewsletterLink ? ctaLink : null}
              css={css`
                margin-top: ${scaleSpacing(6)};
              `}
            >
              {ctaLabel}
            </Button>
          )}
        </ContentContainer>

        {hasScrollDown && (
          <ButtonScroll onClick={scrollDown}>
            <IconSvg
              css={arrowStyle}
              name="arrow"
              size={16}
              height={37}
              fill={colors.lightgrey}
            />
          </ButtonScroll>
        )}
      </TopContainer>

      {showAd && (
        <AdContainer>
          <AdBanner config={{ ...ads, smartadserver_format_id: 94933 }} />
        </AdContainer>
      )}
    </Container>
  );
};

Teaser.propTypes = {
  headline: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  description: PropTypes.array,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.object,
  image: PropTypes.object.isRequired,
  hasScrollDown: PropTypes.bool,
  hasNewsletterLink: PropTypes.bool,
  showAd: PropTypes.bool,
  heightVh: PropTypes.number,
  ads: PropTypes.object,
  isSticky: PropTypes.bool,
};

Teaser.defaultProps = {
  hasScrollDown: false,
  showAd: false,
  isSticky: false,
};

export default Teaser;
